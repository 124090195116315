import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#2D2926',
    [Prop.CONTENT]: '#2D2926',
    [Prop.LINK_TXT]: '#2D2926',
    [Prop.LINK_TXT_HOVER]: '#E87722',
    [Prop.BTN_BG]: '#EFE4CE',
    [Prop.BTN_TXT]: '#2D2926',
    [Prop.BTN_BG_HOVER]: '#ed924e',
    [Prop.BTN_TXT_HOVER]: '#2D2926',
    [Prop.BTN_BG_ACTIVE]: '#f3d54e',
    [Prop.BTN_TXT_ACTIVE]: '#2D2926',
    [Prop.BTN_BG_INACTIVE]: '#D5D4D4',
    [Prop.BTN_TXT_INACTIVE]: '#2D2926',
    [Prop.BORDER]: '#D3BC8D',
    [Prop.ELEMENT]: '#D3BC8D',
    [Prop.ELEMENT_TXT]: '#2D2926',
    [Prop.ICON]: '#E87722',
    [Prop.ICON_HOVER]: '#ed924e',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#FCF8F5',
    [Prop.HEADER]: '#2D2926',
    [Prop.CONTENT]: '#2D2926',
    [Prop.LINK_TXT]: '#2D2926',
    [Prop.LINK_TXT_HOVER]: '#E87722',
    [Prop.BTN_BG]: '#2D2926',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#6c6967',
    [Prop.BTN_TXT_HOVER]: '#FFFFFF',
    [Prop.BTN_BG_ACTIVE]: '#E87722',
    [Prop.BTN_TXT_ACTIVE]: '#2D2926',
    [Prop.BTN_BG_INACTIVE]: '#D5D4D4',
    [Prop.BTN_TXT_INACTIVE]: '#2D2926',
    [Prop.BORDER]: '#D3BC8D',
    [Prop.ELEMENT]: '#D3BC8D',
    [Prop.ELEMENT_TXT]: '#2D2926',
    [Prop.ICON]: '#2D2926',
    [Prop.ICON_HOVER]: '#E87722',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#EFE4CE',
    [Prop.HEADER]: '#2D2926',
    [Prop.CONTENT]: '#2D2926',
    [Prop.LINK_TXT]: '#2D2926',
    [Prop.LINK_TXT_HOVER]: '#E87722',
    [Prop.BTN_BG]: '#E87722',
    [Prop.BTN_TXT]: '#2D2926',
    [Prop.BTN_BG_HOVER]: '#ed924e',
    [Prop.BTN_TXT_HOVER]: '#2D2926',
    [Prop.BTN_BG_ACTIVE]: '#f3d54e',
    [Prop.BTN_TXT_ACTIVE]: '#2D2926',
    [Prop.BTN_BG_INACTIVE]: '#D5D4D4',
    [Prop.BTN_TXT_INACTIVE]: '#2D2926',
    [Prop.BORDER]: '#2D2926',
    [Prop.ELEMENT]: '#EFE4CE',
    [Prop.ELEMENT_TXT]: '#2D2926',
    [Prop.ICON]: '#2D2926',
    [Prop.ICON_HOVER]: '#E87722',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#D3BC8D',
    [Prop.HEADER]: '#2D2926',
    [Prop.CONTENT]: '#2D2926',
    [Prop.LINK_TXT]: '#2D2926',
    [Prop.LINK_TXT_HOVER]: '#F3D54E',
    [Prop.BTN_BG]: '#f3d54e',
    [Prop.BTN_TXT]: '#2D2926',
    [Prop.BTN_BG_HOVER]: '#D2CE9E',
    [Prop.BTN_TXT_HOVER]: '#2D2926',
    [Prop.BTN_BG_ACTIVE]: '#D2CE9E',
    [Prop.BTN_TXT_ACTIVE]: '#2D2926',
    [Prop.BTN_BG_INACTIVE]: '#D5D4D4',
    [Prop.BTN_TXT_INACTIVE]: '#2D2926',
    [Prop.BORDER]: '#2D2926',
    [Prop.ELEMENT]: '#EFE4CE',
    [Prop.ELEMENT_TXT]: '#2D2926',
    [Prop.ICON]: '#2D2926',
    [Prop.ICON_HOVER]: '#E87722',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#2D2926',
    [Prop.HEADER]: '#FFFFFF',
    [Prop.CONTENT]: '#FFFFFF',
    [Prop.LINK_TXT]: '#FFFFFF',
    [Prop.LINK_TXT_HOVER]: '#f3d54e',
    [Prop.BTN_BG]: '#E87722',
    [Prop.BTN_TXT]: '#2D2926',
    [Prop.BTN_BG_HOVER]: '#ed924e',
    [Prop.BTN_TXT_HOVER]: '#2D2926',
    [Prop.BTN_BG_ACTIVE]: '#f3d54e',
    [Prop.BTN_TXT_ACTIVE]: '#2D2926',
    [Prop.BTN_BG_INACTIVE]: '#D5D4D4',
    [Prop.BTN_TXT_INACTIVE]: '#2D2926',
    [Prop.BORDER]: '#EFE4CE',
    [Prop.ELEMENT]: '#f3d54e',
    [Prop.ELEMENT_TXT]: '#2D2926',
    [Prop.ICON]: '#f3d54e',
    [Prop.ICON_HOVER]: '#E87722',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#2D2926',
    [Prop.NAV_BG]: '#2D2926',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_ACTIVE]: '#E87722',
    [Prop.NAV_ITEM_BG_ACTIVE]: '#2D2926',
    [Prop.NAV_ITEM_ACTIVE_ACCENT]: '#2D2926',
    [Prop.WW_ICON_COLOR]: '#FFD200',
  },
};
