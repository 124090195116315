import {
  FontSize,
  FontFamily,
  TextTransformOptions,
  Components,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"LFT Etica Sheriff"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"Oswald-Bold"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.MED30,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"Oswald"',
    textTransform: TextTransformOptions.UPPERCASE,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
};

const fontOverrides = {
  [Components.CONTENT_BLOCK_HERO]: {
    header: {
      size: '40px',
    },
    headerMobile: {
      size: '40px',
    },
  },
};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
